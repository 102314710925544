import React from "react"
import Footer from "../../../components/Footer/Footer";
import SupportLeftNav from "../../../components/TwoColumnLeftNav/SupportLeftNav";
import LocalizedLink from "../../../components/localizedLink";
import ContactFormReg from "../../../components/ContactFormReg/ContactFormReg";
import {LocaleContext} from "../../../components/layout";

class Registration extends React.Component {
    constructor() {
        super();
        this.state = {
            showForm: false,
            showButton: true,
        }
    }

    agreeBtnOperation() {
        this.setState({
            showForm: true,
            showButton: false
        })
    }
    render() {
        return (
            <>
                <article className="mail-info two-column-left-nav">
                    <SupportLeftNav/>
                    <section className="main-content">
                        <div>
                            <h2 className={'large bold'}>お客様製品登録</h2>
                            <div className="content-container">
                                <div className="content">
                                    <p>当社製品をご利用いただき、誠にありがとうございます。</p>
                                    <p>製品登録をおこなう際は、下記の内容をよくお読みになり、ご理解・ご同意の上ご登録してください。</p>
                                    <p>【個人情報の使用目的について】</p>
                                    <p>ご登録内容について、下記の使用目的のためにお客様の個人情報を収集させていただいております。</p>
                                    <p>・ お客様へのメール、お電話などでのご連絡</p>
                                    <p>・ 製品、サービス内容向上のためのご登録内容の利用</p>

                                    <p> ※プライバシーポリシーは<LocalizedLink to="/company/privacy"
                                                                   className="direct-link">こちら</LocalizedLink>のページよりご確認いただけます。</p>
                                </div>
                            </div>
                            {
                                this.state.showButton ?
                                    <div>
                                        <div className="agreement-pc">
                                            <LocalizedLink to="/support">
                                                <input className="disagree-btn" type="button" value="同意しません"
                                                       name="disagree"/>
                                            </LocalizedLink>
                                            <input className="agree-btn" type="button" value="上記内容に同意し、入力フォームに進む"
                                                   onClick={() => this.agreeBtnOperation()} name="agree"/>
                                        </div>
                                        <div className="agreement-sp">
                                            <input className="agree-btn" type="button" value="上記内容に同意し、入力フォームに進む"
                                                   onClick={() => this.agreeBtnOperation()} name="agree"/>
                                            <LocalizedLink to="/support">
                                                <input className="disagree-btn" type="button" value="同意しません"
                                                       name="disagree"/>
                                            </LocalizedLink>
                                        </div>
                                    </div>
                                    : null
                            }
                            {
                                this.state.showForm ?
                                    <ContactFormReg/>
                                    : null
                            }
                        </div>
                    </section>
                </article>
                <Footer/>
            </>
        )
    }
}

const Output = () => {
    const {locale} = React.useContext(LocaleContext);
    return (
        <>
            {
                (locale === 'ja') ?
                    <Registration/>
                    :
                    ''
            }
        </>
    )
}
export default Output