import React from "react";
import Amplify, {API} from 'aws-amplify'
import awsconfig from '../../aws-exports'

Amplify.configure(awsconfig);

class ContactFormReg extends React.Component {
    constructor() {
        super();
        this.state = {
            submitButton: true
        }
    }

    postInquiry = async (body) => {
        const APIName = 'satoAmplifyRegTest'
        const path = '/items'
        const params = {
            body: body
        }
        return await API.post(APIName, path, params)
    }

    submit() {
        const form = document.getElementById("submit_form")
        let q2_content = '';
        if (form.q2.value === 'その他') {
            q2_content = `その他: ${form.q2othertext.value}`
        } else {
            q2_content = form.q2.value;
        }
        let q4_content = '';
        form.q4.forEach(element => {
            if (element.checked === true) {
                if (element.value === 'その他') {
                    q4_content += `${element.value}: ${form.q4othertext.value}\n`
                } else {
                    q4_content += `${element.value}\n`
                }
            }
        })
        if (this.state.submitButton === true) {
            if (form.name.value === "" || form.company.value === "" || form.zip.value === "" || form.address.value === "" || form.tel.value === "" || form.email.value === "" || form.productName.value === "") {
                window.alert('必須項目を入力してください')
                return
            }
            this.setState({submitButton: false})
            this.postInquiry({
                name: form.name.value,
                company: form.company.value,
                department: form.department.value,
                zip: form.zip.value,
                address: form.address.value,
                tel: form.tel.value,
                fax: form.fax.value,
                email: form.email.value,
                productNo: form.productNo.value,
                productName: form.productName.value,
                productSerialNo: form.productSerialNo.value,
                retailer: form.retailer.value,
                date: form.date.value,
                q1: form.q1.value,
                q2: q2_content,
                q3_1: form.q3_1.value,
                q3_2: form.q3_2.value,
                q4: q4_content
            }).then(res => {
                window.alert(`製品の登録が完了しました。\n登録ID:${res.registration_id}`)
            }).catch(e => {
                window.alert(e)
            })
        } else {
            window.alert('製品の登録は完了しています。\n別の製品を登録する場合は、ページの更新またはリセットボタンを押してください。')
        }
    }

    resetBtnOperation() {
        const form = document.getElementById("submit_form")
        for (let i = 0; i < form.q2.length; i++) {
            form.q2[i].checked = false;
        }
        for (let i = 0; i < form.q4.length; i++) {
            form.q4[i].checked = false;
        }
        this.name.value = "";
        this.company.value = "";
        this.department.value = "";
        this.zip.value = "";
        this.address.value = "";
        this.tel.value = "";
        this.fax.value = "";
        this.email.value = "";
        this.productNo.value = "";
        this.productName.value = "";
        this.productSerialNo.value = "";
        this.retailer.value = "";
        this.date.value = "";
        this.q1.value = "";
        this.q3_1.value = "";
        this.q3_2.value = "";
        this.q2othertext.value = "";
        this.q4othertext.value = "";
        this.setState({submitButton: true});
        alert('フォームの内容をリセットします。');
    }


    render() {
        return (
            <div className="mail-form">
                <h3>ご登録フォーム</h3>
                <p className="asterisk-text">の部分は必須項目です。</p>
                <p className="sp">（任意）以外は必須項目です。</p>
                <form id="submit_form">
                    <table>
                        <tbody>
                        <tr>
                            <td className="name">ご氏名</td>
                            <td><input type="text" id="name" name="name" placeholder="ご氏名"
                                       ref={(el) => (this.name = el)}/></td>
                        </tr>
                        <tr>
                            <td className="company">御社名</td>
                            <td className="desc-td">
                                <input type="text" id="company" name="company" placeholder="御社名"
                                       ref={(el) => (this.company = el)}/>
                                <br/><span className="company-text">個人のお客様の場合は「個人」とご記入ください。</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="department">所属部署名<span className="not-required">（任意）</span></td>
                            <td><input type="text" id="department" name="department" placeholder="所属部署名"
                                       ref={(el) => (this.department = el)}/></td>
                        </tr>
                        <tr>
                            <td className="zip">郵便番号</td>
                            <td><input type="text" id="zip" name="zip" placeholder="郵便番号"
                                       ref={(el) => (this.zip = el)}/></td>
                        </tr>
                        <tr>
                            <td className="address">ご住所</td>
                            <td><input type="text" id="address" name="address" placeholder="ご住所"
                                       ref={(el) => (this.address = el)}/></td>
                        </tr>
                        <tr>
                            <td className="telephone">電話番号</td>
                            <td><input type="text" id="tel" name="tel" placeholder="電話番号"
                                       ref={(el) => (this.tel = el)}/></td>
                        </tr>
                        <tr>
                            <td className="fax">FAX番号<span className="not-required">（任意）</span></td>
                            <td><input type="text" id="fax" name="fax" placeholder="FAX番号"
                                       ref={(el) => (this.fax = el)}/></td>
                        </tr>
                        <tr>
                            <td className="e-mail">E-Mail</td>
                            <td><input type="text" id="e-mail" name="email" placeholder="E-Mail"
                                       ref={(el) => (this.email = el)}/></td>
                        </tr>
                        <tr>
                            <td className="productNo">製品番号<span className="not-required">（任意）</span></td>
                            <td className="desc-td"><input type="text" id="productNo" name="productNo" placeholder="製品番号"
                                       ref={(el) => (this.productNo = el)}/><br/>
                                <span className="productNo-text">記入例：1234-00</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="productName">モデル名または製品名</td>
                            <td className="desc-td"><input type="text" id="productName" name="productName" placeholder="モデル名または製品名"
                                       ref={(el) => (this.productName = el)}/><br/>
                                <span className="productName-text">記入例：記憶計、SK-1260</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="productSerialNo">製品シリアルNo.<span className="not-required">（任意）</span></td>
                            <td className="productSerialNo-td desc-td">
                                <input type="text" id="productSerialNo" name="productSerialNo" placeholder="製品シリアルNo."
                                       ref={(el) => (this.productSerialNo = el)}/><br/>
                                <span className="productSerialNo-text">シリアルNo.が無い場合、記入は不要です。</span><br/>
                                <span className="productSerialNo-text">複数台の場合、「,」や「/」などで区切ってください。</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="retailer">ご購入先<span className="not-required">（任意）</span></td>
                            <td><input type="text" id="retailer" name="retailer" placeholder="ご購入先"
                                       ref={(el) => (this.retailer = el)}/></td>
                        </tr>
                        <tr>
                            <td className="date">ご購入日<span className="not-required">（任意）</span></td>
                            <td><input type="text" id="date" name="date" placeholder="ご購入日"
                                       ref={(el) => (this.date = el)}/></td>
                        </tr>
                        </tbody>
                    </table>
                    <h3 className="questionnaire-title mt-s">アンケートにご協力ください。</h3>
                    <p className="q1">・お買い上げ製品をどのようにお使いになりますか？</p>
                    <p><textarea id="q1" name="q1" rows="8" placeholder="ご記入欄" ref={(el) => (this.q1 = el)}/></p>
                    <p className="q2">・当社製品をどこでお知りになりましたか？</p>
                    <div className="radio q2-content">
                        <label><input type="radio" className="q2-radio" name="q2" value="カタログ"/><span>カタログ</span></label><br/>
                        <label><input type="radio" className="q2-radio" name="q2" value="店頭"/><span>店頭</span></label><br/>
                        <label><input type="radio" className="q2-radio" name="q2" value="当社広告"/><span>当社広告</span></label><br/>
                        <label><input type="radio" className="q2-radio" name="q2"
                                      value="販売店のすすめ"/><span>販売店のすすめ</span></label><br/>
                        <label><input type="radio" className="q2-radio" name="q2"
                                      value="ホームページ"/><span>ホームページ</span></label><br/>
                        <label><input type="radio" className="q2-radio" name="q2" value="その他"/><span>その他</span></label>
                        <input type="text" name="q2othertext" id="q2othertext" ref={(el) => (this.q2othertext = el)}/>
                    </div>
                    <p className="q3 mt-s">・業種・職種を教えてください。</p>
                    <label className="q3-1" htmlFor="q3_1">業種：</label>
                    <select id="q3_1" name="q3_1" ref={(el) => (this.q3_1 = el)}>
                        <option value="製造業">製造業</option>
                        <option value="飲食業">飲食業</option>
                        <option value="建設業">建設業</option>
                        <option value="運輸業">運輸業</option>
                        <option value="倉庫業">倉庫業</option>
                        <option value="サービス業">サービス業</option>
                        <option value="製薬・医療">製薬・医療</option>
                        <option value="農林業">農林業</option>
                        <option value="教育">教育</option>
                        <option value="公的機関">公的機関</option>
                        <option value="その他">その他</option>
                    </select><br className="confirmation-sp"/>
                    <label className="q3-2" htmlFor="q3_2" >職種：</label>
                    <select id="q3_2" name="q3_2" ref={(el) => (this.q3_2 = el)}>
                        <option value="製造">製造</option>
                        <option value="品質管理">品質管理</option>
                        <option value="設計・開発・技術">設計・開発・技術</option>
                        <option value="教職">教職</option>
                        <option value="事務職">事務職</option>
                        <option value="（会社）管理者">（会社）管理者</option>
                        <option value="その他">その他</option>
                    </select>
                    <p className="q4 mt-s">・ご購入の際、どのような点に留意されましたか？（複数回答可）</p>
                    <div className="checkbox">
                        <label><input type="checkbox" className="q4" name="q4" value="機能（ハード）性能"/><span>機能（ハード）性能</span></label><br/>
                        <label><input type="checkbox" className="q4" name="q4" value="機能（ソフト）性能"/><span>機能（ソフト）性能</span></label><br/>
                        <label><input type="checkbox" className="q4" name="q4"
                                      value="デザイン"/><span>デザイン</span></label><br/>
                        <label><input type="checkbox" className="q4" name="q4" value="価格"/><span>価格</span></label><br/>
                        <label><input type="checkbox" className="q4" name="q4" value="その他"/><span>その他</span></label>
                        <input type="text" name="q4othertext" id="q4othertext" ref={(el) => (this.q4othertext = el)}/>
                    </div>
                </form>
                <p>
                    ご記入ありがとうございます。<br/>これからも当社製品をご愛顧のほど、よろしくお願いいたします。
                </p>
                <div className="confirmation-pc">
                    <input className="reset-btn" type="button" value="リセット" onClick={() => this.resetBtnOperation()}
                           name="reset">
                    </input>
                    <input className="confirm-btn" type="button" value="送信" name="confirm"
                           onClick={(event) => this.submit(event)}>
                    </input>
                </div>
                <div className="confirmation-sp">
                    <input className="confirm-btn" type="button" value="送信" name="confirm"
                           onClick={() => this.submit()}>
                    </input>
                    <input className="reset-btn" type="button" value="リセット" onClick={() => this.resetBtnOperation()}
                           name="reset">
                    </input>
                </div>
            </div>
        )
    }
}

export default ContactFormReg;